<template>
  <div>
    <sdFeatherIcons type="user" size="14" />
    <sdButton type="primary" size="small"></sdButton>
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
